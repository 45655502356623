import type { Client, UserProfile } from "../../../../../client";
import { Button, ImageButton } from "../../../../../components";

import { getImageUrl } from "../../../../../utils/image";
interface Props {
  profile?: UserProfile;
  client?: Client;
  handleClose?: () => void;
}

export const LinkCreatorHeader = ({ profile, client, handleClose }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center gap-4 px-4 sm:px-5 py-4 bg-gray-1 border-b border-gray-2 rounded-tl-2xl">
      <div className="flex flex-row sm:px-4 items-center justify-center gap-2">
        <ImageButton
          imageUrl={profile?.profilePhotoId ? getImageUrl(profile.profilePhotoId) : undefined}
          alt={typeof profile?.profileData.name === "string" ? profile.profileData.name : "Profile"}
          indicatorProps={
            client?.logoId
              ? {
                  variant: "image",
                  imageUrl: getImageUrl(client.logoId),
                  alt: "Logo",
                }
              : undefined
          }
        />
        <p className="text-sm hidden sm:block">Edit unique profile version</p>
      </div>
      <Button variant="primary" onClick={handleClose}>
        Close and update
      </Button>
    </div>
  );
};
