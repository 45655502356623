import { Suspense, useEffect } from "react";
import {
  Outlet,
  isRouteErrorResponse,
  useLocation,
  useParams,
  useRouteError,
} from "react-router-dom";
import { client } from "../../../client";
import { FadeInUpContainer, NavigationBar } from "../../../components";
import { useGetOrganization } from "../../../services";
import { addClientTab, addMemberTab } from "../../../state/tabs";
import { getImageUrl } from "../../../utils/image";
import { isAxiosError } from "../../../utils/query-client";
import { Loading } from "./components/Loading";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: We scroll to top when the pathname changes
  useEffect(() => {
    const timeoutId = setTimeout(() => window.scrollTo(0, 0), 10);
    return () => clearTimeout(timeoutId);
  }, [pathname]);

  return null;
};

const AddToState = () => {
  const { memberId, clientId } = useParams();

  useEffect(() => {
    if (!memberId) return;
    // Add the member tab
    addMemberTab(memberId);
  }, [memberId]);

  useEffect(() => {
    if (!clientId) return;
    // Add the client tab
    addClientTab(clientId);
  }, [clientId]);

  return null;
};

// TODO: Move this to App.tsx ?
const apiBaseUrl = process.env.REACT_APP_BASE_URL
  ? String(process.env.REACT_APP_BASE_URL)
  : "https://profiletool-api.onrender.com";

client.setConfig({
  baseUrl: apiBaseUrl,
});

client.interceptors.request.use((request, options) => {
  const token = localStorage.getItem("profiletool-auth-token");
  if (token) request.headers.set("Authorization", `Bearer ${token}`);
  return request;
});

client.interceptors.response.use((response) => {
  if (response.status === 401) {
    localStorage.removeItem("profiletool-auth-token");
    window.location.href = "/";
  }
  return response;
});

const DashboardLayout = () => {
  const { organizationId } = useParams();
  const { pathname } = useLocation();

  // TODO: Handle error better
  if (!organizationId) throw new Error("Organization ID is required");

  const { data } = useGetOrganization(organizationId);

  return (
    <div
      data-vaul-drawer-wrapper
      className="container mx-auto flex flex-col gap-4 md:gap-8 bg-gray-1"
    >
      <Suspense fallback={<Loading />}>
        <NavigationBar organizationLogo={data?.logoId ? getImageUrl(data.logoId) : undefined} />

        <FadeInUpContainer key={pathname}>
          <ScrollToTop />
          <AddToState />
          <Outlet />
        </FadeInUpContainer>
      </Suspense>
    </div>
  );
};

DashboardLayout.displayName = "DashboardLayout";

export const DashboardLayoutErrorBoundary = () => {
  const error = useRouteError();

  return isRouteErrorResponse(error) ? (
    <h1>
      {error.status} {error.statusText}
    </h1>
  ) : isAxiosError(error) ? (
    <h1>{error.code}</h1>
  ) : (
    <h1>Error</h1>
  );
};

export default DashboardLayout;
