import React, { useState, useEffect } from "react";
import Button, { ButtonStyleType } from "./atoms/Button";
import {
  type ProfileSkillTag,
  type SideProject,
  Workplace,
  type ProfileComponentProps,
  ImageUpload,
  ProjectPhoto,
  type ContentSuggestionRequestBody,
  type WorkplaceItem,
  type WorkplaceLinkConfig,
} from "../types";
import { GitHub, Link, Loader, Plus, Settings, Target } from "react-feather";
import "./components.css";
import EditorTextButton from "./atoms/EditorTextButton";
import SkillTag from "./SkillTag";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import EditorIconButton from "./atoms/EditorIconButton";
import VkTextArea from "./legacy/VkTextArea";
import EditorTextField from "./EditorTextField";
import { Menu } from "react-feather";
import dayjs from "dayjs";
import animationData from "../assets/spinner-animation.json";
import { getContentImprovementSuggestion } from "../requests";
import Lottie from "react-lottie";
interface WorkplaceEditorProps extends ProfileComponentProps {
  workplace: any;
  sideProject?: any;
  isEditable: boolean;
  isNew: boolean;
  onSaveItem: (workplace: WorkplaceItem) => void;
  onCancelEdit?: () => void;
  onRemove: () => void;
  onShowDialog: (dialogType: string, workplaceId: number) => void;
  isBeingEdited: boolean;
  isLastInOrder: boolean;
  onContentImprovementStarted?: () => void;
  onContentImprovementEnded?: () => void;
  workplaceName?: string;
  workplaceLinkConfig?: WorkplaceLinkConfig;
  numberOfConnectedProjects?: number;
  removePadding?: boolean;
}

function WorkplaceEditor({
  isDarkModeActive,
  isEditable,
  sideProject,
  workplace,
  isNew,
  onSaveItem,
  isBeingEdited,
  onCancelEdit,
  onRemove,
  onShowDialog,
  isLastInOrder,
  onContentImprovementStarted,
  onContentImprovementEnded,
  workplaceLinkConfig,
  numberOfConnectedProjects,
  removePadding,
}: WorkplaceEditorProps) {
  const [currentSideProject, setCurrentSideProject] = useState<SideProject>({
    ...sideProject,
  });
  const [currentWorkplace, setCurrentWorkplace] = useState<WorkplaceItem>({
    ...workplace,
  });
  useEffect(() => {
    setCurrentSideProject(workplace);
  }, [workplace]);
  const [contentSuggestionRequestBody, setContentSuggestionRequestBody] =
    useState<ContentSuggestionRequestBody | null>(null);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [pitchHaveFocus, setPitchHaveFocus] = useState<boolean>(false);

  const [isLoadingContentSuggestion, setIsLoadingContentSuggestion] =
    useState<boolean>(false);
  const [currentImproventSuggestions, setCurrentImproventSuggestions] =
    useState<string>("");

  const [showWriterAssistant, setShowWriterAssistant] =
    useState<boolean>(false);

  const contentSuggestionActions = [
    "Less formal",
    "More formal",
    "Shorter",
    "Funnier",
    "Less braggy",
  ];

  const displayDescription = () => {
    if (
      workplaceLinkConfig != undefined &&
      !workplaceLinkConfig.displayDescription
    ) {
      return false;
    }

    return currentWorkplace.description !== "" || isEditable;
  };

  const displaySkillTags = () => {
    if (
      workplaceLinkConfig != undefined &&
      !workplaceLinkConfig.displaySkills
    ) {
      return false;
    }

    let projectSkills: ProfileSkillTag[] = [];
    if (workplace && workplace.skillTags) {
      projectSkills = workplace.skillTags;
    }

    return projectSkills.length !== 0 || isEditable;
  };

  const workplaceNameContent = () => {
    if (isEditable) {
      return workplace.name;
    }
    if (
      numberOfConnectedProjects !== undefined &&
      numberOfConnectedProjects > 0 &&
      workplaceLinkConfig !== undefined &&
      workplaceLinkConfig.displayConnectedProjects
    ) {
      return (
        workplace.name + " - " + numberOfConnectedProjects + " connect projects"
      );
    }

    return workplace.name;
  };

  const onTitleInput = (input: string) => {
    const newWorkplace = { ...currentWorkplace, name: input };
    onSaveItem(newWorkplace);
    setCurrentWorkplace(newWorkplace);
  };

  const onRoleInput = (input: string) => {
    const newWorkplace = { ...currentWorkplace, role: input };
    onSaveItem(newWorkplace);
    setCurrentWorkplace(newWorkplace);
  };

  const onPitchInput = (input: string) => {
    setPitchHaveFocus(false);
    const newWorkplace = { ...currentWorkplace, description: input };
    onSaveItem(newWorkplace);
    setCurrentWorkplace(newWorkplace);
  };

  const parseDate = (dateString: string) => {
    if (dateString === "") {
      return "current";
    }

    return dayjs(dateString, "YYYY").format("YYYY");
  };

  let projectSkills: ProfileSkillTag[] = [];
  if (workplace && workplace.skillTags) {
    projectSkills = workplace.skillTags;
  }

  return (
    <div
      className={"project-editor"}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
    >
      <div className="flex flex-1 flex-row">
        {showSidebar ? (
          <div className="flex-1 flex flex-col items-center align-center">
            <EditorTextButton
              text="Remove"
              handleClick={() => onRemove()}
            ></EditorTextButton>
          </div>
        ) : null}

        <div className="flex flex-[5] flex-col">
          <div style={{ flex: 1, flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              {removePadding === true ? null : (
                <div
                  className={
                    "border-[1px] border-op-dark mb-[4px] max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center" +
                    (isMouseHovering && isEditable ? " visible" : " invisible")
                  }
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                  }}
                >
                  <Settings size={12} color="#202124" />
                </div>
              )}

              <div
                className={
                  "flex-1 " + (removePadding === true ? null : " pl-[8px]")
                }
              >
                {isEditable ? (
                  <EditorTextField
                    disableHoverUnderline={!isEditable}
                    disableEditing={!isEditable}
                    isDarkModeActive={isDarkModeActive}
                    autofocus={false}
                    styles={{ display: "inline", width: "100%" }}
                    placeholder="Your title / role"
                    onLeaveField={onRoleInput}
                    value={currentWorkplace.role}
                    inputStyles={{ fontSize: 18, fontWeight: 600 }}
                    useDynamicWidth
                  />
                ) : (
                  <label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {currentWorkplace.role}
                  </label>
                )}
              </div>
            </div>

            <div
              className={
                "flex flex-1 " + (removePadding === true ? "" : "pl-[26px]")
              }
            >
              <div className="grow">
                <EditorTextField
                  disableEditing={!isEditable}
                  disableHoverUnderline={!isEditable}
                  isDarkModeActive={isDarkModeActive}
                  placeholder="Workplace name"
                  onLeaveField={onTitleInput}
                  inputStyles={{
                    fontSize: 14,
                    fontWeight: "normal",
                    marginBottom: "8px",
                  }}
                  value={workplaceNameContent()}
                />
              </div>
              <div>
                {isEditable ? (
                  <EditorTextButton
                    disableEditing={!isEditable}
                    text={
                      workplace.startDate === ""
                        ? "when"
                        : parseDate(workplace.startDate) +
                          " - " +
                          (workplace.endDate === ""
                            ? "current"
                            : parseDate(workplace.endDate))
                    }
                    handleClick={() => {
                      onShowDialog("workplace-dates", 0);
                    }}
                  />
                ) : (
                  <label className="text-details-text">
                    {workplace.startDate === ""
                      ? "when"
                      : parseDate(workplace.startDate) +
                        " - " +
                        (workplace.endDate === ""
                          ? "current"
                          : parseDate(workplace.endDate))}
                  </label>
                )}
              </div>
            </div>

            {displayDescription() ? (
              <div>
                {removePadding === undefined ? (
                  <div className="flex flex-1 flex-row">
                    <div className={"flex-1 pl-[26px]"}>
                      <VkTextArea
                        isEditable={isEditable}
                        isDarkModeActive={isDarkModeActive}
                        placeholder="Briefly outline the main duties and responsibilities you had in the role. You can expand on achivements and projects in the Project section below"
                        onLeaveField={onPitchInput}
                        value={currentWorkplace.description}
                        disableHoverUnderline={!isEditable}
                        maxLength={250}
                        suggestion={currentImproventSuggestions}
                        onFocus={() => setPitchHaveFocus(true)}
                        onHoverChange={(isHovering: boolean) =>
                          setPitchHaveFocus(isHovering)
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {displaySkillTags() ? (
              <div
                className={removePadding === true ? "" : "pl-[26px]"}
                style={{ display: "flex", flexWrap: "wrap", marginTop: 5 }}
              >
                {projectSkills.map(
                  (skillOption: ProfileSkillTag, index: number) => {
                    return (
                      <EditorSkillTagButton
                        disableEditing={!isEditable}
                        isDarkModeActive={isDarkModeActive}
                        key={index}
                        text={skillOption.name}
                        profileSkillTag={skillOption}
                        handleClick={() => {
                          onShowDialog("tags", workplace.id);
                        }}
                      />
                    );
                  },
                )}
              </div>
            ) : null}

            {isEditable ? (
              <div className="pl-[26px]">
                <EditorTextButton
                  disableEditing={!isEditable}
                  isDarkModeActive={isDarkModeActive}
                  text="Add tag"
                  handleClick={() => onShowDialog("tags", workplace.id)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkplaceEditor;
