import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import {
  faAt,
  faCircleInfo,
  faGlobe,
  faPerson,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { useRef, useState } from "react";
import { toast } from "sonner";
import isURL from "validator/lib/isURL";
import type { Client, Lead } from "../../../../../client";
import {
  Button,
  ImageButton,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  isEmailOrEmpty,
  isPhoneNumberOrEmpty,
  isUrlOrEmpty,
  trim,
} from "../../../../../components";
import { useCreateClient } from "../../../../../services";
import { useRequestClientInfo } from "../../../../../services/organtization/client/request-client-info";
import { jsonParse } from "../../../../../services/utils/parse";
import { getImageUrl } from "../../../../../utils/image";
import { LeadRadioRowCardSection } from "../card/LeadRadioRowCardSection";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { ExpandableSection } from "./ExpandableSection";
import { PanelContainer } from "./PanelContainer";

interface Props {
  client?: Client;
  clients?: Client[];
  lead?: Lead;
  onMutateLead: (lead: Partial<Lead>) => void;
  onMutateClient: (client: Partial<Client>) => void;
  organizationId: string;
}

type SelectedClientType = "existing" | "new";

const getFormattedUrl = (url: string) => {
  const u = new URL(url.startsWith("http") ? url : `https://${url}`);
  return u.toString();
};

export const ClientPanel = ({
  client,
  clients,
  lead,
  onMutateLead,
  onMutateClient,
  organizationId,
}: Props) => {
  const createClientToastId = useRef<string | number>();
  const requestInfoToastId = useRef<string | number>();

  const [selectedClientType, setSelectedClientType] =
    useState<SelectedClientType>("existing");

  const selectClient = (client: Client) => {
    onMutateLead({ clientId: client.id });
    setSelectedClientType("existing");
  };

  const getExistingClient = (url: string) => {
    return clients
      ?.filter(({ url }) => isURL(url))
      .find((c) => getFormattedUrl(c.url) === url);
  };

  const createClient = useCreateClient({
    onMutate: () => {
      createClientToastId.current = toast.loading("Creating client...");
    },
    onSuccess: (client) => {
      toast.success("Client created", { id: createClientToastId.current });
      selectClient(jsonParse<Client>(client));
    },
    onError: () => {
      toast.error("Something went wrong", { id: createClientToastId.current });
    },
  });

  const requestClientInfo = useRequestClientInfo({
    onMutate: () => {
      requestInfoToastId.current = toast.loading(
        "Getting info about client...",
      );
    },
    onSuccess: (clientInfo, { query: { url } }) => {
      toast.success("Retrieved client info", {
        id: requestInfoToastId.current,
      });
      debugger;

      // Check if clientInfo exists in clients
      if (getExistingClient(url)) return;

      debugger;
      // Create client when client info is retrieved if it doesn't exist
      createClient.mutate({
        body: {
          id: "",
          organizationId,
          name: clientInfo.name,
          url,
          description: clientInfo.description,
          logoId: clientInfo.logoId,
        },
        path: {
          organizationID: organizationId,
        },
      });
    },
    onError: () => {
      toast.error("Something went wrong", { id: requestInfoToastId.current });
    },
  });

  const onRequestClient = (url: string) => {
    if (!isURL(url) || requestClientInfo.isPending || createClient.isPending)
      return;

    const formattedUrl = getFormattedUrl(url);
    const client = getExistingClient(url);

    if (client) selectClient(client);
    else requestClientInfo.mutate({ query: { url: formattedUrl } });
  };

  const disabled = requestClientInfo.isPending || createClient.isPending;

  return (
    <PanelContainer>
      <div className="flex flex-col gap-4">
        <LeadRowCard type="solid">
          <LeadRadioRowCardSection
            title="Pick existing client"
            selected={selectedClientType === "existing"}
            onCheckedChange={(checked) =>
              setSelectedClientType(checked ? "existing" : "new")
            }
          >
            <AnimatePresence>
              {selectedClientType === "existing" && (
                <ExpandableSection className="grid grid-cols-5 gap-4 w-full">
                  {clients?.map((c) => (
                    <TooltipProvider key={c.id} delayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="w-min">
                            <ImageButton
                              alt={c.name}
                              imageUrl={
                                c.logoId ? getImageUrl(c.logoId) : undefined
                              }
                              active={c.id === client?.id}
                              onClick={() => onMutateLead({ clientId: c.id })}
                            />
                          </div>
                        </TooltipTrigger>
                        {c.name && (
                          <TooltipContent>
                            <p>{c.name}</p>
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </ExpandableSection>
              )}
            </AnimatePresence>
          </LeadRadioRowCardSection>
        </LeadRowCard>
        <LeadRowCard type="solid">
          <LeadRadioRowCardSection
            title="Add new client"
            selected={selectedClientType === "new"}
            onCheckedChange={(checked) =>
              setSelectedClientType(checked ? "new" : "existing")
            }
          >
            <AnimatePresence>
              {selectedClientType === "new" && (
                <ExpandableSection className="flex flex-col gap-2">
                  <p className="text-sm">Add domain or e-mail to see magic!</p>
                  <Input
                    leftIcon={faAt}
                    label="E-mail"
                    disabled={disabled}
                    transformValue={trim}
                    validate={isEmailOrEmpty}
                    type="email"
                    rightButton={{
                      label: "Go!",
                      onClick: (email) => {
                        const emailDomain = email.split("@")[1];
                        if (emailDomain)
                          onRequestClient(`https://${emailDomain}`);
                        else onRequestClient(email);
                      },
                    }}
                  />
                  <Input
                    leftIcon={faGlobe}
                    label="Domain"
                    disabled={disabled}
                    transformValue={trim}
                    validate={isUrlOrEmpty}
                    type="url"
                    rightButton={{
                      label: "Go!",
                      onClick: (url) => onRequestClient(url),
                    }}
                  />
                </ExpandableSection>
              )}
            </AnimatePresence>
          </LeadRadioRowCardSection>
        </LeadRowCard>
      </div>
      <LeadRowCard type="dashed">
        <LeadRowCardSection title="Client information">
          <Input
            leftIcon={faBuilding}
            label="Client name"
            value={client?.name}
            onFinishWriting={(clientName) =>
              onMutateClient({ name: clientName })
            }
            transformValue={trim}
          />
          <Input
            leftIcon={faGlobe}
            label="Client website"
            value={client?.url}
            onFinishWriting={(clientUrl) => onMutateClient({ url: clientUrl })}
            transformValue={trim}
            validate={isUrlOrEmpty}
            rightButton={
              client?.url
                ? {
                    label: "Visit",
                    onClick: () => {
                      window.open(getFormattedUrl(client.url), "_blank");
                    },
                  }
                : undefined
            }
            type="url"
          />
          <Input
            leftIcon={faCircleInfo}
            label="Client description"
            value={client?.description}
            rows={3}
            onFinishWriting={(clientDescription) =>
              onMutateClient({ description: clientDescription })
            }
            transformValue={trim}
          />
        </LeadRowCardSection>
        <LeadRowCardSection title="Pick image">
          <div className="flex flex-row flex-wrap gap-2">
            {requestClientInfo.data?.logoSuggestions &&
            client?.url &&
            getFormattedUrl(requestClientInfo.data.url) ===
              getFormattedUrl(client.url)
              ? requestClientInfo.data.logoSuggestions.map((logo, i) => (
                  <ImageButton
                    key={logo}
                    alt={`Logo suggestion ${i}`}
                    imageUrl={getImageUrl(logo)}
                    onClick={() => onMutateClient({ logoId: logo })}
                  />
                ))
              : client?.url && (
                  <Button
                    onClick={() =>
                      requestClientInfo.mutate({
                        query: { url: getFormattedUrl(client.url) },
                      })
                    }
                    disabled={disabled}
                  >
                    Retrieve logo suggestions
                  </Button>
                )}
          </div>
        </LeadRowCardSection>
        <LeadRowCardSection title="Contact information">
          <Input
            leftIcon={faPerson}
            label="Contact name"
            value={lead?.contactPersonName}
            onFinishWriting={(contactPersonName) =>
              onMutateLead({ contactPersonName })
            }
            transformValue={trim}
          />
          <Input
            leftIcon={faAt}
            label="E-mail"
            value={lead?.contactPersonEmail}
            onFinishWriting={(contactPersonEmail) =>
              onMutateLead({ contactPersonEmail })
            }
            transformValue={trim}
            validate={isEmailOrEmpty}
            type="email"
          />
          <Input
            leftIcon={faPhone}
            label="Phone number"
            value={lead?.contactPersonPhone}
            onFinishWriting={(contactPersonPhone) =>
              onMutateLead({ contactPersonPhone })
            }
            transformValue={trim}
            validate={isPhoneNumberOrEmpty}
            type="tel"
          />
        </LeadRowCardSection>
      </LeadRowCard>
    </PanelContainer>
  );
};

ClientPanel.displayName = "ClientPanel";
