import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import { useSnapshot } from "valtio";
import vocsLogo from "../../assets/vocs-logo.png";
import amplyLogo from "../../assets/amply.png";

import { tabsState } from "../../state/tabs";
import { MenuSection } from "../menu-section";
import { cn } from "../utils/cn";
import { ClientButtonLink } from "./ClientButtonLink";
import { IconButtonLink } from "./IconButtonLink";
import { ImageButtonLink } from "./ImageButtonLink";
import { MemberButtonLink } from "./MemberButtonLink";
import { getBaseUrl, scrollToTop } from "./utils";

interface Props {
  organizationId: string;
  className?: string;
  organizationLogo?: string;
}

const NavigationDesktopBar = ({
  organizationId,
  className,
  organizationLogo,
}: Props) => {
  const tabsSnap = useSnapshot(tabsState);
  const baseUrl = getBaseUrl(organizationId);

  return (
    <div
      className={cn(
        "flex flex-row gap-10 items-center flex-grow flex-shrink min-w-0",
        className,
      )}
    >
      <ImageButtonLink
        href={baseUrl}
        imageUrl={amplyLogo}
        alt="VOCS Studio"
        onClick={scrollToTop}
      />
      <div className="flex flex-row gap-8 items-center flex-grow flex-shrink overflow-x-scroll">
        <MenuSection>
          <IconButtonLink href={`${baseUrl}/members`} icon={faUser} />
          {tabsSnap.members.length ? <MenuSection.Separator /> : null}
          {tabsSnap.members.map((memberId) => (
            <MemberButtonLink
              key={memberId}
              organizationId={organizationId}
              memberId={memberId}
            />
          ))}
        </MenuSection>
        <MenuSection>
          <IconButtonLink href={`${baseUrl}/clients`} icon={faBuilding} />
          {tabsSnap.clients.length ? <MenuSection.Separator /> : null}
          {tabsSnap.clients.map((clientId) => (
            <ClientButtonLink
              key={clientId}
              organizationId={organizationId}
              clientId={clientId}
            />
          ))}
        </MenuSection>
      </div>
    </div>
  );
};

NavigationDesktopBar.displayName = "NavigationDesktopBar";

export { NavigationDesktopBar };
