import { useCallback, useRef } from "react";
import { toast } from "sonner";
import type { Lead } from "../../../../client";
import { useGetClient, useUpdateLead } from "../../../../services";
import { LeadRow } from "./LeadRow";

interface Props {
  organizationId: string;
  lead: Lead;
  index?: number;
}

export const ExistingLeadRow = ({ organizationId, lead, index }: Props) => {
  if (!lead.id) throw new Error("Missing lead ID");

  const toastId = useRef<string | number>();

  const updateLead = useUpdateLead({
    onMutate: (payload) => {
      if (payload.body.status === "archived") {
        toastId.current = toast.loading("Archiving lead...");
      }
    },
    onSuccess: (_, { body: { status } }) => {
      if (status === "archived") toast.success("Lead archived", { id: toastId.current });
    },
    onError: () => {
      toast.error("Something went wrong", { id: toastId.current });
    },
  });

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead.clientId,
    organizationID: organizationId,
  });

  const onUpdateLead = useCallback(
    (payload: Partial<Lead>) => {
      updateLead.mutate({
        path: { organizationID: organizationId, leadID: lead.id },
        body: { ...lead, ...payload },
      });
    },
    [updateLead, organizationId, lead]
  );

  return (
    <LeadRow
      lead={updateLead.variables?.body ?? lead}
      organizationId={organizationId}
      client={client}
      onUpdateLead={onUpdateLead}
    />
  );
};

ExistingLeadRow.displayName = "ExistingLeadRow";
