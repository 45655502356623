export interface ProfilePreview {
  id: string;
  profile_name: string;
  isPublic: boolean;
  link_id: string;
}

export interface AccountOverview {
  profileId: string;
  profileLinks: ProfileLink[];
  profilePhotoId?: string;
  profile: Profile;
  guestProfileLinks: GuestProfileLink[];
  profileManagers?: ProfileManager[];
  isManagerProfile: boolean;
}

export interface ProfileOverview {
  profileId: string;
  profileLinks: ProfileLink[];
  profilePhotoId?: string;
  profileData: AccountProfile;
  guestProfileLinks: GuestProfileLink[];
  profileManagers?: ProfileManager[];
  isManagerProfile: boolean;
  managerProfiles: UserProfile[];
  profile: UserProfile;
  organizationProfile?: OrganizationProfile;
  isCurrentProfile: boolean;
  isManagerForCurrentProfile: boolean;
  isAdmin: boolean;
}

export interface AccountProfile {
  name: string;
  profile: Profile;
  didDismissWelcomeMessage: boolean;
  profileLinkId: string;
  profilePhotoId?: string;
  email: string;
  linkConfig?: ProfileLinkConfig;
  isOwner: boolean;
  isCreator?: boolean;
  linkCreatedByProfileId?: string;
  isManager?: boolean;
  isManagerProfile: boolean;
  projectPhotos: ProjectPhoto[];
}

export interface ProjectPhoto {
  assetId: string;
  projectId: string;
}

export interface ProfileVisit {
  profileId: string;
  profileLinkId: string;
  profileVisitId: string;
}

export interface ProfileSettingsUpdate {
  id: string;
  name: string;
}

export interface Profile {
  id: string;
  profile_name: string;
  name: string;
  title: string;
  pitch: string;
  ui_config: UIConfig;
  roles_section?: RolesSection;
  skills_section?: SkillsSection;
  side_projects_section?: SideProjectsSession;
  profile_tags: ProfileTag[];
  profileSkillSummary?: SkillCount[];
  workplaces: WorkplaceItem[];
}

export interface SkillCount {
  name: string;
  projectIds: string[];
}

export interface OrganisationSkillCount {
  name: string;
  projectIds: string[];
  profileIds: string[];
}

export interface UIConfig {
  dark_mode: boolean;
  use_profile_picture: boolean;
}

export const getNonUsedSectionTypes = (profile: Profile) => {
  const nonUsedSectionTypes: SectionType[] = [];

  if (profile.roles_section === null) {
    nonUsedSectionTypes.push(SectionType.Roles);
  }

  if (profile.skills_section === null) {
    nonUsedSectionTypes.push(SectionType.Skills);
  }
  return nonUsedSectionTypes;
};

export interface Skill {
  title: string;
  order: number;
  skill_level: number;
  isPlaceholder: boolean;
}

export interface SkillBlock {
  order: number;
  title: string;
  skillCategory: string;
  skills: Skill[];
}

export interface Role {
  title: string;
  company: string;
  order: number;
  start_date: string;
  end_date: string;
  description: string;
  highlighted_projects: string[];
  skillTags: ProfileSkillTag[];
}

export enum SectionType {
  PersonalDetails = 0,
  Roles = 1,
  Skills = 2,
}

export const getSectionTypeName = (sectionType: SectionType) => {
  switch (sectionType) {
    case SectionType.Roles:
      return "Work Experience";
    case SectionType.Skills:
      return "Skills";
  }
};

export interface Workplace {
  id: string;
  name: string;
}

export interface WorkplaceItem {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  role: string;
  order: number;
  skillTags: ProfileSkillTag[];
}

export interface SideProject {
  id: string;
  order: number;
  title: string;
  pitch: string;
  githubUrl: string;
  websiteUrl: string;
  workplace?: Workplace | null;
  skillTags: ProfileSkillTag[];
  workplaceId?: string;
  isSideProject: boolean;
}

export interface ProfileComponentProps {
  isDarkModeActive?: boolean;
}

export interface SectionProps extends ProfileComponentProps {
  onDeleteSectionClick: () => void;
  title: string;
  order: number;
  totalSectionCount: number;
  isEditable: boolean;
}

export interface RolesSection {
  order: number;
  title: string;
  roles: Role[];
}

export interface SkillsSection {
  order: number;
  title: string;
  skill_blocks: SkillBlock[];
}

export interface SideProjectsSession {
  order: number;
  title: string;
  side_projects: SideProject[];
}

export interface SectionTypeDescription {
  title: string;
  info: string;
  order: number;
  icon?: React.ReactNode;
  type: string;
}

export interface ProfileTag {
  tag_type: string;
  value: string;
  order: number;
}

export interface ProfileInputHelpBlock {
  type: string;
  title: string;
  info: string;
}

export interface ProfileVisitDisplayModel {
  profileVisitId: string;
  city: string;
  createdAt: string;
  profileLinkId: string;
}

export interface Account {
  id: string;
  email: string;
  verified: boolean;
}

export interface ProfileSkillTag {
  name: string;
  customIconName: string;
}

export interface ProfileLink {
  link: string;
  config: ProfileLinkConfig;
  visitCount: number;
  totalVisitCount: number;
  profileLinkOwner?: ShortProfile;
}

export interface ShortProfile {
  profileId: string;
  name: string;
}

export interface GuestUserProfileLink extends ProfileLink {
  dynamicLink: string;
}

export interface GuestProfileLink {
  dynamicLink: string;
  link: string;
}

export interface GuestUserDynamicProfile {
  name: string;
  profilePictureImageBase64: string;
  guestUserProfileLinks: GuestUserProfileLink[];
}

export interface ProfileLinkConfig {
  darkMode: boolean;
  useProfilePicture: boolean;
  name: string;
  profileLinkPersonalInfoConfig: ProfileLinkPersonalInfoConfig;
  profileLinkProjects: ProfileLinkProject[];
  branding?: Branding | null;
  dynamic: boolean;
  showCustomPitch: boolean;
  customPitch: string;
  profileLinkWorkplaceIds: string[];
  workplaceLinkConfig: WorkplaceLinkConfig;
  projectsLinkConfig: ProjectsLinkConfig;
  contactUsLinkConfig: ContactUsLinkConfig;
}

export interface WorkplaceLinkConfig {
  displaySkills: boolean;
  displayDescription: boolean;
  displayConnectedProjects: boolean;
}
export interface ProjectsLinkConfig {
  displaySkills: boolean;
}

export interface ContactUsLinkConfig {
  displayContactUs: boolean;
  contactProfileId: string;
  phone: string;
}

export interface ProfileLinkResponseBody {
  accountProfile: AccountProfile;
  profileContactDetails: ProfileContactDetails;
}

export interface ProfileLinkPersonalInfoConfig {
  showPersonalInfo: boolean;
  showName: boolean;
  showPitch: boolean;
  roundMode: boolean;
}

export interface ProfileLinkProject {
  projectId: string;
  order: number;
}

export interface Branding {
  logotypes: Logotype[];
}

export interface Logotype {
  assetUrl: string;
  placement: string;
  size: number;
}

export interface ProfileManager {
  managerProfileId: string;
  profileId: string;
  status: string;
  profile: Profile;
  profilePhotoId?: string;
}

export interface ShortProfile {
  id: string;
  name: string;
}

export interface OrganisationOverview {
  profileManagers: ProfileManager[];
  managerInviteCode: string;
  logoId: string;
  managerProfile: UserProfile;
  profiles: UserProfile[];
}

export interface OrganizationOverview {
  id: string;
  profileManagers: ProfileManager[];
  name: string;
  inviteCode: string;
  logoId: string;
  managerProfile: UserProfile;
  profiles: UserProfile[];
  currentOrgProfile: OrganizationProfile;
  isAdmin: boolean;
}

export interface AdminOverview {
  adminViewProfileSummaries: AdminViewProfileSummary[];
  organizations: Organization[];
}

export interface AdminViewProfileSummary {
  profileId: string;
  profile: Profile;
  linkCount: number;
  email: string;
  isManager: boolean;
  profilePhotoId?: string;
  organizations: Organization[];
}

export interface ImageUpload {
  base64File: string;
  fileType: string;
}

export interface UserProfile {
  profileId: string;
  profileData: Profile;
  profilePhotoId: string;
  isManager: boolean;
  activeContract?: ActiveContract;
}

export interface ActiveContract {
  contract: Contract;
  client: Client;
}

export interface OrganizationProfile {
  profileId: string;
  organizationId: string;
  role: string;
  inviteCode: string;
}

export interface ContentSuggestionRequestBody {
  originalText: string;
  messages: ContentSuggestionChatMessage[];
  type: string;
}

export interface ContentSuggestionChatMessage {
  order: number;
  content: string;
  fromUser: boolean;
}

export interface ImprovedResponse {
  improvedContent: string;
  validationString: string;
}

export interface ProfileContactDetails {
  profileId: string;
  name: string;
  email: string;
  profilePhotoId: string;
  logoId: string;
  phoneNumber: string;
  website: string;
  iconLogoId?: string;
}

export interface Validation {
  one: boolean;
  two: boolean;
  three: boolean;
  four: boolean;
}

export interface InviteResponseBody {
  organization: Organization;
  inviterName: string;
}

export interface Organization {
  id: string;
  name: string;
  logoId: string;
}

export interface SignInResponseBody {
  profileId: string;
  organizationId: string;
  isNewUser: boolean;
}

export interface Client {
  id: string;
  name: string;
  logoId?: string;
  logoSuggestions?: string[];
  organizationId: string;
  url: string;
  description: string;
}

export interface Lead {
  id: string;
  title: string;
  description?: string;
  clientId: string;
  contactPersonName: string;
  contactPersonEmail?: string;
  contactPersonPhone?: string;
  profileLinkId?: string;
  profileId?: string;
  status: string;
  assignmentInfo?: AssignmentInfo;
  priority?: number;
  comments: ResourceComment[];
  events: ResourceEvent[];
  numberOfUnreadEvents: number;
  contractId?: string;
  contract?: Contract;
}

export interface ClientPreviewData {
  logoImageUrls: string[];
  title: string;
  description: string;
}

export interface ClientWithLeads extends Client {
  leads: Lead[];
}

export interface AssignmentInfo {
  title: string;
  workplace: string;
  workplaceInfo: string;
  location: string[];
  remote: string;
  skills: string[];
  allSkills: string[];
  responsibilities: string[];
  assignmentUrl: string;
}

export interface ResourceComment {
  id: string;
  resourceId: string;
  commenterProfileId: string;
  comment: string;
  createdAt: string;
  commenterUserProfile?: UserProfile; //Frontend prop
}

export interface ResourceEvent {
  resourceId: string;
  triggeredByProfileId: string;
  eventType: string;
  createdAt: string;
  triggeredByUserProfile?: UserProfile; //Frontend prop
  // TODO: Add diff type
  diff?: any;
}

export interface Contract {
  id: string;
  startDate: string;
  endDate: string | null;
}
