import "./App.css";

import "./index.css";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Toaster } from "sonner";
import { LoadingSpinner } from "./components";
import SignIn from "./components/SignIn";
import AccountSettings from "./pages/AccountSettings";
import Admin from "./pages/Admin";
import Auth from "./pages/Auth";
import GuestUserOverview from "./pages/GuestUserOverview";
import Landing from "./pages/Landing";
import LinkSettings from "./pages/LinkSettings";
import ManagerPage from "./pages/ManagerPage";
import ManagerRequestPage from "./pages/ManagerRequestPage";
import Onboarding from "./pages/Onboarding";
import OrganizationOverviewPage from "./pages/OrganizationOverview";
import Overview from "./pages/Overview";
import PageContainer from "./pages/PageContainer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfileEditor from "./pages/ProfileEditor";
import ProfileViewer from "./pages/ProfileViewer";
import { buildClientsLoader } from "./pages/dashboard/clients/loader";
import DashboardHome from "./pages/dashboard/home/page";
import { buildLayoutLoader } from "./pages/dashboard/layout/loader";
import DashboardLayout, {
  DashboardLayoutErrorBoundary,
} from "./pages/dashboard/layout/page";
import { buildMemberLoader } from "./pages/dashboard/member/loader";
import { buildMembersLoader } from "./pages/dashboard/members/loader";
import {
  clientRoute,
  clientsRoute,
  memberRoute,
  membersRoute,
} from "./utils/lazy-routes";
import { queryClient } from "./utils/query-client";

/*
function App() {
  return (
    <StatsigProvider
      sdkKey="client-QM4Yt2VpUy7yUOUxnvZocFp60w6pCwCw5cV4wwVffZk"
      user={{userID:"123"}}
      waitForInitialization={true}
      // StatsigOptions (Not Required)

    >
      <div className="App"><h3>hello</h3></div>
    </StatsigProvider>
  );
}
*/

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Landing />} />
      <Route path="/controlboard" element={<Overview />} />
      <Route path="/editor/:externalProfileId" element={<ProfileEditor />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/p/:profileLinkId" element={<ProfileViewer />} />
      <Route path="/add-link" element={<LinkSettings />} />
      <Route path="/links/:profileLinkId" element={<LinkSettings />} />
      <Route path="/account" element={<AccountSettings />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/onboarding/:externalProfileId" element={<Onboarding />} />
      <Route path="/guest" element={<GuestUserOverview />} />
      <Route path="/manager" element={<ManagerPage />} />
      <Route
        path="/organizations/:organizationId"
        element={<OrganizationOverviewPage />}
      />
      <Route
        path="/manager/:managerProfileId/profiles/:profileId"
        element={<Overview />}
      />
      <Route path="/profiles/:profileId" element={<Overview />} />
      <Route
        path="/manager/:managerProfileId/profiles/:profileId/links"
        element={<LinkSettings />}
      />
      <Route
        path="/manager/:managerProfileId/links/:profileLinkId"
        element={<LinkSettings />}
      />
      <Route path="/invites/:inviteCode" element={<ManagerRequestPage />} />
      <Route path="/admin/profiles/:profileId" element={<Overview />} />
      <Route path="/org/:organizationId" element={<PageContainer />} />
      <Route
        path="/dash/:organizationId"
        loader={buildLayoutLoader(queryClient)}
        element={<DashboardLayout />}
        errorElement={<DashboardLayoutErrorBoundary />}
      >
        <Route index element={<DashboardHome />} />
        <Route
          path="members"
          loader={buildMembersLoader(queryClient)}
          lazy={membersRoute}
        />
        <Route
          path="members/:memberId"
          loader={buildMemberLoader(queryClient)}
          lazy={memberRoute}
        />
        <Route
          path="clients"
          loader={buildClientsLoader(queryClient)}
          lazy={clientsRoute}
        />
        <Route path="clients/:clientId" lazy={clientRoute} />
      </Route>
      <Route path="/signin" element={<SignIn />} />

      {/* TODO: Add default (404) route */}
      {/*   <Route path="*" element={<NotFound />} /> */}
    </>,
  ),
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        icons={{
          loading: <LoadingSpinner size={32} />,
        }}
      />
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
