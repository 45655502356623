import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "../../services";
import { useInitTabsState } from "../../state/tabs";
import { getImageUrl } from "../../utils/image";
import { clientRoute, clientsRoute, memberRoute, membersRoute } from "../../utils/lazy-routes";
import { ImageButton } from "../buttons";
import { Image } from "../image";
import { Popover, PopoverMenu, PopoverTrigger } from "../popover";
import { cn } from "../utils/cn";
import { NavigationDesktopBar } from "./NavigationDesktopBar";
import { NavigationDrawer } from "./NavigationDrawer";
import { NavigationMobileBar } from "./NavigationMobileBar";

interface Props {
  organizationLogo?: string;
}

export const NavigationBar = ({ organizationLogo }: Props) => {
  const { organizationId } = useParams();
  const { data: user } = useGetUser();
  const navigate = useNavigate();

  useInitTabsState();

  useEffect(() => {
    // Prefetch lazy routes
    membersRoute();
    memberRoute();
    clientsRoute();
    clientRoute();
  }, []);

  // TODO: Handle error better
  if (!organizationId) throw new Error("Organization ID is required");

  const userImage = user?.profileSummary.profilePhotoId
    ? getImageUrl(user.profileSummary.profilePhotoId)
    : undefined;

  return (
    <motion.div
      initial={{ opacity: 0, y: -12 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.15 }}
      id="navigation-bar"
      className={cn(
        "flex flex-row gap-4 justify-between items-center bg-gray-1",
        "sticky top-0 px-5 py-4 md:pt-12 md:pb-6 z-20"
      )}
    >
      <NavigationDesktopBar
        organizationId={organizationId}
        className="hidden md:flex"
        organizationLogo={organizationLogo}
      />

      <NavigationMobileBar
        organizationId={organizationId}
        className="flex md:hidden"
        organizationLogo={organizationLogo}
      />

      <div className="flex md:hidden flex-grow-0 flex-shrink-0">
        <NavigationDrawer
          organizationId={organizationId}
          organizationLogo={organizationLogo}
          userImage={userImage}
        />
      </div>
      <div className="hidden md:flex flex-grow-0 flex-shrink-0">
        <Popover>
          <PopoverTrigger asChild>
            <ImageButton
              imageUrl={userImage}
              alt={user?.profileSummary.name ?? "Profile"}
              indicatorProps={
                organizationLogo
                  ? {
                      variant: "image",
                      imageUrl: organizationLogo,
                      alt: "Logo",
                    }
                  : undefined
              }
            />
          </PopoverTrigger>
          <PopoverMenu>
            <PopoverMenu.Item onClick={() => console.log("company settings")}>
              <Image src={organizationLogo} alt="Company" className="w-6 h-6 rounded-lg" />
              <span>company settings</span>
            </PopoverMenu.Item>
            {user && (
              <PopoverMenu.Item
                onClick={() =>
                  navigate(`/dash/${organizationId}/members/${user.profileSummary.id}`)
                }
              >
                <Image src={userImage} alt="Profile" className="w-6 h-6 rounded-lg" />
                <span>your profile</span>
              </PopoverMenu.Item>
            )}
            <PopoverMenu.Item onClick={() => console.log("sign out")}>
              <FontAwesomeIcon
                width={14}
                height={14}
                icon={faArrowRightFromBracket}
                className="p-[5px]"
              />
              <span>sign out</span>
            </PopoverMenu.Item>
          </PopoverMenu>
        </Popover>
      </div>
    </motion.div>
  );
};

NavigationBar.displayName = "NavigationBar";
