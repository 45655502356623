import { Suspense, useCallback, useMemo, useRef } from "react";
import { toast } from "sonner";
import isURL from "validator/lib/isURL";
import { useSnapshot } from "valtio";
import type { AssignmentInfo, Client, Lead } from "../../../../client";
import { LoadingSpinner } from "../../../../components";
import {
  useCreateLead,
  useGetClient,
  useGetClients,
  useGetLead,
  useUpdateClient,
  useUpdateLead,
} from "../../../../services";
import { useRequestAssignmentInfo } from "../../../../services/organtization/request-assigment-info";
import { jsonParse } from "../../../../services/utils/parse";
import { type LeadRowActiveState, homeState } from "../../../../state/home";
import { useToggleActiveState } from "../utils/use-toggle-active-state";
import {
  AnimatedPanel,
  AssignmentPanel,
  ClientPanel,
  EventsPanel,
  MemberPanel,
  QRPanel,
} from "./panel";

interface Props {
  organizationId: string;
  leadId?: string;
  state: LeadRowActiveState;
  divider?: boolean;
  className?: string;
}

const blankLead: Lead = {
  id: "",
  clientId: "",
  title: "",
  description: "",
  contactPersonName: "",
  contactPersonEmail: "",
  contactPersonPhone: "",
  contractId: "",
  status: "",
  numberOfUnreadEvents: 0,

  // Will always get overridden before being sent to the backend
  orgId: "",
};

export const LeadPanelContent = ({ organizationId, leadId, state, divider, className }: Props) => {
  const { selectedLead } = useSnapshot(homeState);

  const updateLead = useUpdateLead();
  const updateClient = useUpdateClient();

  const { scrollToLead } = useToggleActiveState({ leadId: leadId ?? null });

  const createLead = useCreateLead({
    onSuccess: (lead) => {
      const newLead = jsonParse<Lead>(lead);

      if (selectedLead) {
        // Navigate to the new lead
        homeState.selectedLead = {
          leadId: newLead.id,
          state,
        };

        scrollToLead(newLead.id);
      }
    },
  });

  const { data: lead } = useGetLead(
    { organizationID: organizationId, leadID: leadId ?? "" },
    {
      enabled: !!leadId,
    }
  );

  const { data: clients } = useGetClients(organizationId);

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead?.clientId ?? "",
    organizationID: organizationId,
  });

  const onMutateLead = useCallback(
    (payload: Partial<Lead>) => {
      if (lead) {
        // Update existing lead if it exists
        updateLead.mutate({
          path: { organizationID: organizationId, leadID: lead.id },
          body: { ...lead, ...payload },
        });
      } else {
        // Create new lead if it doesn't exist
        createLead.mutate({
          body: {
            ...blankLead,
            orgId: organizationId,
            ...payload,
          },
          path: {
            organizationID: organizationId,
          },
        });
      }
    },
    [lead, updateLead, organizationId, createLead]
  );

  const onMutateClient = useCallback(
    (payload: Partial<Client>) => {
      if (!client) return;

      updateClient.mutate({
        path: { organizationID: organizationId, clientID: client.id },
        body: { ...client, ...payload },
      });
    },
    [updateClient, organizationId, client]
  );

  const requestInfoToastId = useRef<string | number>();
  const requestAssignmentInfo = useRequestAssignmentInfo({
    onMutate: () => {
      requestInfoToastId.current = toast.loading("Getting info about assignment...");
    },
    onSuccess: (assignmentInfo, { query: { url } }) => {
      toast.success("Updated assignment info", { id: requestInfoToastId.current });
      onMutateLead({
        assignmentInfo: { ...jsonParse<AssignmentInfo>(assignmentInfo), assignmentUrl: url },
      });
    },
    onError: () => {
      toast.error("Something went wrong", { id: requestInfoToastId.current });
    },
  });

  const leadProfileId = updateLead.variables?.body.profileId ?? lead?.profileId ?? undefined;

  const child = useMemo(() => {
    switch (state) {
      case "client":
        return (
          <ClientPanel
            client={client}
            clients={clients}
            lead={lead}
            onMutateLead={onMutateLead}
            onMutateClient={onMutateClient}
            organizationId={organizationId}
          />
        );
      case "assignment":
        return (
          <AssignmentPanel
            disabled={requestAssignmentInfo.isPending}
            onRequestAssignmentInfo={(url) => {
              if (isURL(url) && !requestAssignmentInfo.isPending) {
                requestAssignmentInfo.mutate({ query: { url } });
              }
            }}
            lead={lead}
            onMutateLead={onMutateLead}
          />
        );
      case "member":
        return <MemberPanel profileId={leadProfileId} onMutateLead={onMutateLead} />;
      case "qr":
        return <QRPanel profileLinkId={lead?.profileLinkId} leadId={lead?.id} />;
      case "events":
        return (
          <EventsPanel
            organizationId={organizationId}
            leadId={lead?.id}
            events={lead?.events ?? []}
            onResetNumberOfUnreadEvents={() => onMutateLead({ numberOfUnreadEvents: 0 })}
          />
        );
      default:
        return null;
    }
  }, [
    state,
    client,
    clients,
    lead,
    onMutateLead,
    onMutateClient,
    requestAssignmentInfo,
    leadProfileId,
    organizationId,
  ]);

  return (
    <AnimatedPanel state={state} className={className}>
      {divider && <div className="w-full h-px mt-4 bg-gray-2" />}
      <div className="py-8 lg:px-4">
        <Suspense fallback={<Loading />}>{child}</Suspense>
      </div>
    </AnimatedPanel>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
